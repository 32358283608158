<template>
  <div id="form">
    <h2>Проверьте Ваши данные</h2>
    <div class="infoBlock">
      <div class="fio">
        <p>Личные данные</p>
        <formSelectDadata :refs="'LAST_NAME'"></formSelectDadata>
        <formSelectDadata :refs="'FIRST_NAME'"></formSelectDadata>
        <formSelectDadata :refs="'SECOND_NAME'"></formSelectDadata>
        <formSelectDadata :refs="'RESIDENTIAL_ADDRESS'" @blur="startGeo()"></formSelectDadata>
        <formInput :refs="'BIRTHDAY'"></formInput>
        <formInput :refs="'PHONE'"></formInput>
        <formInput :refs="'EMAIL'"></formInput>
        <div id="sex">
          Ваш пол:
          <formRadio :refs="'GENDER'" :sman="'M'"></formRadio>
          <formRadio :refs="'GENDER'" :sman="'F'"></formRadio>
          <span class="errorMsg" v-if="genderVuex.ShowError">{{
            genderVuex.msg
          }}</span>
        </div>
      </div>
      <div class="passport">
        <p>Паспорт</p>
        <formInput :refs="'PASSPORT_SERIES'"></formInput>
        <formInput :refs="'PASSPORT_NUMBER'"></formInput>
        <formInput :refs="'PASSPORT_DEP_CODE'"></formInput>
        <formInput :refs="'PASSPORT_DATE'"></formInput>
        <formInput :refs="'BIRTHPLACE'"></formInput>
        <formSelectDadata :refs="'PASSPORT_DEP'"></formSelectDadata>
        <formInput :refs="'SNILS'"></formInput>
        <formCheckBox :name="'IS_ADDRESS_COINCIDES'" :trueValue="'Y'" :falseValue="'N'"></formCheckBox>
        <formSelectDadata :refs="'REGISTRATION_ADDRESS'"></formSelectDadata>
      </div>

      <div class="work">
        <p>Финансовая информация</p>
        <formSelectDadata :refs="'WORK_JOB'"></formSelectDadata>
        <formSelect :refs="'EXPER_RADIO'"></formSelect>
        <formInput :refs="'CONST_INCOME'"></formInput>
        <formInput :refs="'AVERAGE_EXPENSES_PER_MONTH'"></formInput>
        <formInput :refs="'AVERAGE_MONTHLY_EXPENSES_FOR_LOAN_PAYMENTS'"></formInput>
        <formInput :refs="'OTHER_SOURCES_OF_INCOME'"></formInput>
        <formSelect :refs="'SOURCE_OF_INCOME'"></formSelect>
        <formSelect :refs="'FINANCIAL_POSITION'" :firstValueSetActive="true"></formSelect>
        <div v-if="!isPdl">
          <formInput :refs="'MEETING_DATE'"></formInput>
          <formInput :refs="'MEETING_HOUR'"></formInput>
        </div>
        <formCheckBox :name="'MINOR_CHILDREN'" :trueValue="true" :falseValue="false"></formCheckBox>

        <div id="mnogabukov">
          <div>
            <div>
              <formCheckBox style="margin-bottom: 0" :name="'NOT_RELATED'" :trueValue="true" :falseValue="false">
              </formCheckBox>
              <a href="#" v-on:click="cdSpoiler(0, $event)" v-if="!spolier">Подробнее</a>
              <a href="#" v-on:click="cdSpoiler(0, $event)" v-if="spolier">Скрыть</a>
            </div>
          </div>
          <div class="forSpoiler" v-bind:class="{ spolier: spolier }">
            <div>
              <div>
                <p>
                  Лицом, на которое возложено или было возложено ранее (с
                  момента сложения полномочий прошло менее 1 года) исполнение
                  важных государственных функций, в частности: - Главой
                  государства (в том числе правящей королевской династии) или
                  правительства; - Министром, его заместителем или помощником; -
                  Высшим правительственным чиновником; - Должностным лицом
                  судебных органов власти (Верховный, Конституционный суд); -
                  Государственным прокурором и его заместителем; - Высшим
                  военным чиновником; - Руководителем или членом Совета
                  директоров Национальных Банков; - Послом; - Руководителем
                  государственных корпораций; - Членом Парламента или иного
                  законодательного органа.
                </p>
              </div>
              <div>
                <p>
                  Лицом, облеченным общественным доверием, в частности: -
                  Руководителем, заместителем руководителя международных
                  организаций (ООН, ОЭСР, ОПЕК, Олимпийский комитет, Всемирный
                  Банк и т.д.); - Членом Европарламента; - Руководителем или
                  членом международных судебных организаций (Суд по правам
                  человека, Гаагский трибунал и др.).
                </p>
              </div>
              <div>
                <p>
                  Должностным лицом публичной международной организации, т.е.
                  лицом, уполномоченным такой организацией действовать от ее
                  имени
                </p>
              </div>
              <div>
                <p>
                  Лицом, замещающим (занимающим) одну из перечисленных
                  должностей: - государственную должность Российской Федерации;
                  - должность члена Совета директоров Центрального банка
                  Российской Федерации; - должность федеральной государственной
                  службы, назначение на которую и освобождение от которой
                  осуществляются Президентом Российской Федерации или
                  Правительством Российской Федерации; - должность в Центральном
                  банке Российской Федерации; - должность в государственной
                  корпорации; - должности в иных организациях, созданных
                  Российской Федерацией на основании федеральных законов,
                  включенные в перечни должностей, определяемые Президентом
                  Российской Федерации
                </p>
              </div>
              <div>
                <p>
                  Родственником вышеуказанной категории лиц, в частности: -
                  Супругом; - Родственником по прямой восходящей и нисходящей
                  линии (родителями и детьми, дедушкой, бабушкой и внуками); -
                  Полнородным или не полнородным (имеющими общих отца или мать)
                  братом или сестрой, усыновителем или усыновленным
                </p>
              </div>
            </div>
          </div>

          <a href="#" v-on:click="cdSpoiler(1, $event)" v-if="spolier">Скрыть</a>
        </div>
        <formCheckBox :name="'MY_OWN_BENEFIT'" :trueValue="true" :falseValue="false"></formCheckBox>
        <formCheckBox :name="'ACTIONS_CONTROL'" :trueValue="true" :falseValue="false"></formCheckBox>
      </div>
      <div class="addInfo">
        <p>Дополнительная информация</p>
        <div>
          <formSelect :refs="'PURPOSE_OF_THE_LOAN'" :firstValueSetActive="true"></formSelect>
          <formSelect :refs="'EDUCATION'"></formSelect>
          <formSelect :refs="'MARITAL_STATUS'"></formSelect>
        </div>
      </div>
    </div>
    <div v-if="isDev">
      <button class="testBtn" @click="fillUserData()">Заполнить тестовыми данными</button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { TestUserInfo } from '@/store/repeatLoan/testData'

export default {
  name: 'formScreenFio',

  components: {
    formInput: () => import('./components/formInput'),
    formRadio: () => import('./components/formRadio'),
    formSelectDadata: () => import('./components/formSelectDadata'),
    formCheckBox: () => import('./components/formCheckBox'),
    formSelect: () => import('./components/formSelect')
  },
  data() {
    return {
      spolier: false
    };
  },
  computed: {
    isDev: () => process.env.NODE_ENV === 'development',
    ...mapGetters('repeatLoan', ['isPdl']),
    ...mapState({
      calc_week: (state) => state.repeatLoan.sendData.CALC_WEEK,
      genderVuex: (state) => state.repeatLoan.workData.GENDER,
      resedentialAddress: (state) =>
        state.repeatLoan.sendData.RESIDENTIAL_ADDRESS,
      lastGeo: (state) => state.repeatLoan.geo.last,
    })
  },
  mounted() {
    const nextScreen = this.isPdl ? 'payment' : undefined;
    this.$store.dispatch('repeatLoan/changeNextScreen', nextScreen);
    if (!this.isPdl) {
      this.startGeo();
    }
  },
  methods: {
    /**
     * открывает скрывает подробное описание кем пользователь не является
     * @param position int 0 без скрола, 1 со скролом в начало текста спойлера, что бы пользователя не бросало в
     * подвал страницы
     * @param $event событие, для блокировки работы тега a по умолчанию
     */
    cdSpoiler(position, $event) {
      $event.preventDefault();
      this.spolier = !this.spolier;
      if (position === 1) {
        this.$emit('scrollingTo', {
          id: 'mnogabukov'
        });
      }
    },
    async startGeo() {
      if (this.lastGeo === this.resedentialAddress) {
        return;
      }
      await this.$store.dispatch('repeatLoan/startGeo');
    },
    async fillUserData() {
      await this.$store.dispatch('repeatLoan/saveUserData', TestUserInfo());
      if (!this.isPdl) {
        await this.startGeo();
      }
    }
  }
};
</script>
<style scoped>
#form > h2 { text-align: left; font-family: 'Lato'; font-size: 6vw;}
#form > p { text-align: left; line-height: 5.60vw }
.infoBlock > div {margin-bottom: 5vw;}
.infoBlock > div > p { font-family: 'Ubuntu'; font-weight: 700; font-size: 4.5vw; }
#sex { display: flex; align-items: center; padding: 6.47vw 0 0 11.76vw; color: rgba(19,21,21,.7019607843137254); font-weight: 500; position: relative }
.optionButton { margin-bottom: 3vw; line-height: normal;}
.forSpoiler{height: 0;transition: 0.3s ease-out;overflow: hidden}
.forSpoiler > div > div > p { color: #959595 }
.forSpoiler > div > div { margin-bottom: 1vw }
.spolier { height: auto }
#mnogabukov  { margin-bottom: 4.71vw }
#mnogabukov > a { color: #43AC34 }
#mnogabukov > div { display: flex; align-items: center; }
#mnogabukov > div > input { width: 8.24vw; height: 8.24vw; margin-right: 2.65vw }
#mnogabukov > div > div > p { color: #959595 }
#mnogabukov > div > div > a { color: #43AC34 }
.testBtn { position: fixed; left: 0; top: 0; padding: 5px; font-size: 10px;}
@media (min-width: 768px) {
	#form > h2 { font-size: 3.5vw;}
	.infoBlock > p { font-size: 2.5vw; }
	.errorMsg { top: 7.8vw}
	#sex { padding: 3.95vw 0 0 1.76vw }
	.optionButton { margin-bottom: 1vw;}
	#mnogabukov  { margin-bottom: 2vw }

}
@media (min-width: 1240px) {
	.infoBlock { display: flex; justify-content: space-between; flex-wrap: wrap;}
	.infoBlock > div { width: 45%; margin-bottom: 30px; }
	.infoBlock > div > p { font-size: 27px; }
	#form > h2 { font-size: 32px;}
	.errorMsg { top: 41px }
	#sex { padding: 0 0 0 10px }
	.optionButton { margin-bottom: 20px;}
	#mnogabukov  { margin-bottom: 20px }
}
</style>
